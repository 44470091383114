@import "assets/styles/variables";

.results-field {
  font-size: 1em;
  padding: 0.7rem 2rem 0.7rem 0.7rem;
  font-weight: $fw-bold;
  white-space: pre-wrap;
  margin-top: 0px;
  overflow: auto;

  &__row {
    margin: 0px 4px;
    white-space: wrap;
  }
}
