@import "assets/styles/variables";

.full-screen-loader {
  position: absolute;
  top: 44px;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1300;

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
